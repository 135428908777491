<template>
  <div class="page">
        <div class="" style="text-align:left;padding-left:20px">
            <a-button class="addBtn fixedBtn" size="large" type="primary" @click="showAdd">添加分类</a-button>
        </div>
       <div class="lists" v-for="item in data" :key="item.id">
            <div class="list_row">
                <div class="flex">
                    <!-- <div><label for="">分类id：</label>{{item.id}}</div> -->
                    <div><label for="">分类名称：</label>{{item.name}}</div>
                </div>
                <div class="flex" style="height:15rem">
                 <div><label for="">分类图标：</label> <img :src="ajaxUrl+'/scrm/index/getFile?id='+item.img"  style="width:65px;"></div>
                </div>
                <div class="btn_con">
                    <!-- <a-button type="primary" size="small" ghost @click="editType(item.id)">编辑</a-button> -->
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
              </div>
        </div>
        <a-modal v-model="visible" title="添加分类"  @ok="addType">
            <div class="addBox">
                <div class="add_title">请输入分类名</div>
                <a-input class="add_inp" v-model="addText" />
            </div>
             <template>
                <img v-if="imageId" :src="ajaxUrl+'/scrm/index/getFile?id='+imageId"  style="width:100px;height:auto;display: block;">
                <label class="fileLabel" for="image"><a-icon type="upload" /> 上传分类图片 </label>
                <input type="file" id="image" ref="image" style="display:none" @change="imgUpload($event)">
                <div style="padding-top:15px;color:#999;font-size:13px">建议尺寸 <span style="color:red">200x200像素</span></div>
            </template>
        </a-modal>
    </div>
    
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,fileUpload,ajaxUrl} from '../../../assets/js/request';
export default {
    data() {
        return {
            key: "",
            type: null,
            status: null,
            visible:false,
            onID:'', //编辑状态id
            data:[],
            addText:'', //添加分类名称
            imageId:'',
            ajaxUrl:ajaxUrl,
            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
        };
    },
    mounted: function(){
        this.getData();
    },
    methods: {
        // 搜索
        onSearch(){
            this.getData(this.key,this.type);
        },
        reset(){
            this.key = "";
            this.type = null;
            this.status = null;
        },
        getData() {
            requestXml("/jieb/Product/showcategory","POST",(res) => {
                console.log(res);
                for(let i=0;i<res.list.length;i++){
                    res.list[i].created = getDateTime(res.list[i].created);
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;
                console.log(this.data);
            },{"form":"mycreated","content": this.key,"type": this.type,"status": this.status,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
          // 图片上传
        imgUpload(e){
            fileUpload(e,(res) => {
                console.log(res);
                this.imageId=res.id;
                 this.$refs.image.value =''
            })
        },
        //添加分类
        showAdd(){
            this.visible = true;
        },
        addType(){
            // console.log(this.addText);
            if(this.addText!=""){
            let url="/jieb/Product/addcategory"
            let data={'name':this.addText,'img':this.imageId}
            if(this.onID){
                url="/jieb/Product/savecategory"
                data.id=this.onID;
            }
            requestXml(url,"POST",(res) => {
                console.log(res)
                this.visible=false;
                this.onID='';
                this.addText='';
                this.imageId='';
                this.getData();
                
            },data)
            }
         
        },
        // 编辑
        editType(id){
           requestXml("/jieb/Product/categorydetail","GET",(res) => {
                this.onID=id;
                this.addText=res.name;
                this.imageId=res.img;
                this.visible=true;
            },{"id": id})
        },
        // 删除
        onDelete(id){
            // console.log(id);
            requestXml("/jieb/Product/delcategory","POST",(res) => {
                console.log(res)
                this.getData();
            },{"id": id})
        }
    },
    
};
</script>
<style scoped>
    .page{padding-top: 15px;padding-bottom: 100px;}
    .search{display: flex;justify-content: space-between;}
    .addBtn{width: auto;}
    .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    };
    .action{display:inline-block;padding:5px 10px;margin:0 5px;background:#dceeff;border:1px solid #36a3ff;color:#36a3ff;border-radius:5px}
    .del{background:#ffdcdc;border-color:#ff4c4c;color:#ff4c4c}
    .add_title{padding: 15px 0;}
    .add_inp{height: 40px;margin-bottom: 60px;}
     .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        /* margin: 15px auto; */
        margin: 0 auto;
        margin-bottom: 15px;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
    }
    

    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
</style>

